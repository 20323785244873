import { environment } from '@env'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import {
  EmailModel, PhoneModel,
  ContactGroupModel,
  ContactModel,
  ContactDetailModel,
  VContactDetailModel, ContactAddressModel, VCustomerModel, PayingMethodModel, OrderedAddressModel
} from '@domain/contact'
import { logger } from 'nx/src/utils/logger'
import { VehicleModel } from '@domain/vehicle'

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) {
  }

  getContacts(): Observable<ContactModel[]> {
    const url = `${environment.apiUrl}/contacts?active=false`
    return this.http.get<ContactModel[]>(url)
  }

  getActiveContacts(): Observable<ContactModel[]> {
    const url = `${environment.apiUrl}/contacts?active=true`
    return this.http.get<ContactModel[]>(url)
  }

  getCustomers(): Observable<ContactModel[]> {
    const url = `${environment.apiUrl}/contacts/customers?active=false`
    return this.http.get<ContactModel[]>(url)
  }

  getActiveCustomers(): Observable<ContactModel[]> {
    const url = `${environment.apiUrl}/contacts/customers?active=true`
    return this.http.get<ContactModel[]>(url)
  }

  getContactById(id: number): Observable<ContactModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}`
    return this.http.get<ContactModel[]>(url)
  }

  getContactVehicleByUserId(id: number): Observable<VehicleModel> {
    const url = `${environment.apiUrl}/contacts/${id}/vehicles`
    return this.http.get<VehicleModel>(url)
  }


  getContactAddresses(): Observable<ContactAddressModel[]> {
    const url = `${environment.apiUrl}/contacts/addresses`
    return this.http.get<ContactAddressModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getContactAddressesByContactId(id: number): Observable<ContactAddressModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}/addresses`
    return this.http.get<ContactAddressModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getOrderedAddressesByContactId(id: number): Observable<OrderedAddressModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}/addresses`
    return this.http.get<OrderedAddressModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  getPhonesByContactId(id:number): Observable<PhoneModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}/phones`
    return this.http.get<PhoneModel[]>(url).pipe(
      map(values => {
        return values
      })
    )
  }

  addContactAddress(contactAddress: ContactAddressModel): Observable<any> {
    const url = `${environment.apiUrl}/contacts/addresses`
    logger.debug('contact-service#addAddressToCustomer: contactAddress=', contactAddress)
    return this.http.post<number>(url, contactAddress).pipe(
      map(value => {
        return value
      })
    )
  }

  getVContactAddressesByContactId(id: number): Observable<VContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}/addresses`
    logger.debug('contact-service#getVContactAddressesByContactId: contactId=', id)
    return this.http.get<VContactDetailModel[]>(url).pipe(
      map(value => {
        return value
      })
    )
  }

  getVContactDetailsByContactId(id: number): Observable<VContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}/details`
    logger.debug('contact-service#getVContactDetailsByContactId: contactId=', id)
    return this.http.get<VContactDetailModel[]>(url).pipe(
      map(value => {
        return value
      })
    )
  }

  getContactsByUserId(id: number): Observable<ContactModel[]> {
    const url = `${environment.apiUrl}/contacts/user/${id}`
    return this.http.get<ContactModel[]>(url)
  }

  addContact(contact: ContactModel): Observable<number> {
    const url = `${environment.apiUrl}/contacts`
    return this.http.post<number>(url, contact)
  }

  updateContact(contact: ContactModel): Observable<number> {
    const url = `${environment.apiUrl}/contacts`
    return this.http.put<number>(url, contact)
  }

  deleteContactsByIds(ids: number[]): Observable<number> {
    const url = `${environment.apiUrl}/contacts/${ids}`
    return this.http.delete<number>(url)
  }

  getContactGroups(): Observable<ContactGroupModel[]> {
    const url = `${environment.apiUrl}/contacts/groups`
    return this.http.get<ContactGroupModel[]>(url)
  }

  getContactDetails(): Observable<ContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/details`
    return this.http.get<ContactDetailModel[]>(url)
  }

  getContactDetailsById(id: number): Observable<ContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/details/${id}`
    return this.http.get<ContactDetailModel[]>(url)
  }

  getContactDetailGroups(): Observable<ContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/details/groups`
    return this.http.get<ContactDetailModel[]>(url)
  }

  getContactDetailsByGroupId(id: number): Observable<ContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/details/groups/${id}`
    return this.http.get<ContactDetailModel[]>(url)
  }

  getEmails(): Observable<EmailModel[]> {
    const url = `${environment.apiUrl}/contacts/emails`
    return this.http.get<EmailModel[]>(url)
  }

  getEmailsByContactId(id: number): Observable<EmailModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}/emails/`
    logger.debug('contact-service#getEmailsByContactId: contactId', id)
    return this.http.get<EmailModel[]>(url)
  }

  addEmail(email: EmailModel): Observable<number> {
    logger.debug('contact-service#add: email=', email)
    const url = `${environment.apiUrl}/contacts/emails`
    return this.http.post<number>(url, email)
  }

  getPhones(): Observable<PhoneModel[]> {
    const url = `${environment.apiUrl}/contacts/phones`
    return this.http.get<PhoneModel[]>(url)
  }


  getVContacts(): Observable<VContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts`
    return this.http.get<VContactDetailModel[]>(url)
  }

  getVContactsById(id: number): Observable<VContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/${id}`
    return this.http.get<VContactDetailModel[]>(url)
  }

  getVContactsByUserId(id: number): Observable<VContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/user/${id}`
    return this.http.get<VContactDetailModel[]>(url)
  }

  getVCustomers(): Observable<VCustomerModel[]> {
    const url = `${environment.apiUrl}/contacts/customers`
    return this.http.get<VCustomerModel[]>(url)
  }

  getVCustomersByContactId(id: number): Observable<VContactDetailModel[]> {
    const url = `${environment.apiUrl}/contacts/customers/${id}`
    return this.http.get<VContactDetailModel[]>(url)
  }
}
